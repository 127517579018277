import { Space, Divider, Select, Button, Input, Table,Tooltip, message, Image, Popover, Form, Popconfirm } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import constants from '../../constants/constants';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';

const { Search } = Input;
const { Option } = Select;

const JobsSinglePost = () => {
  //Variable declaration
  const [singlePostData, setSinglePostData] = useState([]);
  const [supplierLogoUrl, setSupplierLogoUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5000);
  const [searchedValue, setSearchedValue] = useState("");
  const [jobsCount, setJobsCount] = useState(0);
  const [sortedValue, setSorterValue] = useState({
    columnKey: "",
    order: 'descend'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue,setFilterValue]= useState('');
  const { supplierId } = useParams();
  const [form] = Form.useForm();

  //Function on page load
  useEffect(() => {
    sinlePostListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
  }, []);

  //Fetch the jobs upload history for the last 30 days 
  const sinlePostListFetch = async (currentPage, pazeSize, searchValue, sortColumn, isSortDesc, filter='') => {
    setIsLoading(true);
    sortColumn = sortColumn !== undefined ? sortColumn : ""
    let url = WebLinks.GetSinglePosts + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) +"&supplierId="+supplierId+ "&limit=" + pazeSize + "&pageNo=" + currentPage + "&search=" + searchValue + "&sortBy=" + sortColumn + "&sortDesc=" + isSortDesc + "&filter=" + filter
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setSinglePostData(response.resultObj.data);
      setJobsCount(response.resultObj.getCount);
      setSupplierLogoUrl(response.resultObj.supplierLogoURL);
    } else {
      message.error(response.resultObj.message);
    }
    setIsLoading(false)
  };

  //Function on change of application table
  const onChangeJobTable = (pagination, filters, sorter) => {
    if (pagination) {
      if (pageSize !== pagination.pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pagination?.current);
      }
      setPageSize(pagination?.pageSize);
      if (sorter) {
        setSorterValue(sorter);
        sinlePostListFetch(pagination?.current, pagination?.pageSize, searchedValue, sorter.order ? sorter.columnKey : "", sorter.order === "ascend" ? false : true,filterValue);
      } else {
        sinlePostListFetch(pagination?.current, pagination?.pageSize, searchedValue, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true,filterValue);
      }
    }
  };

  //Function on search
  const searchJob = (searchText) => {
    sinlePostListFetch(1, 5000, searchText, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true,filterValue);
    setCurrentPage(1);
    setPageSize(5000);
  }

  //Function on filter change
  const handleSelectChange=(selectedValue)=>{
    form.resetFields();
    setSearchedValue("");
    setFilterValue(selectedValue);
    sinlePostListFetch(1, 5000, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true,selectedValue);
    setCurrentPage(1);
    setPageSize(5000);
  }

  //Function to delete job
  const deleteaJob=async(id)=>{
    setIsLoading(true);
    const jobDeleteResponse = await APIUtils.apiCall("DELETE", WebLinks.DeleteSinglePost + id+ "&buyerId="+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&supplierId="+supplierId);
    if (!jobDeleteResponse.isCatch) {
      message.success(jobDeleteResponse.resultObj.message,5)
      setIsLoading(false);
      sinlePostListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
    } else {
      setIsLoading(false);
      message.error(jobDeleteResponse.resultObj.message);
    }
  }

  // Table columns
  const singlePostColumns = [
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'active',
      align: 'center',
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? 1 : -1),
      render: (_, record) =>
        record.active === true ? (
          <Button
            icon={<CheckOutlined className="text-xl text-lime-500" />}
            type="text"
            // onClick={() => changeActive(record.id, record.active)}
          />
        ) : (
          <Button
            icon={<CloseOutlined className="text-xl text-lime-500" style={{ color: "red" }} />}
            type="text"
            // onClick={() => changeActive(record.id, record.active)}
          />
        ),
    },
    {
      title: 'Reference',
      dataIndex: 'refNumber',
      key: 'refNumber',
      width: '10%',
      sorter: (a, b) => a.refNumber - b.refNumber,
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      width: '25%',
      sorter: (a, b) => a.jobTitle - b.jobTitle,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      sorter: (a, b) => a.location - b.location,
      render: (text,record)=>(<span>{record?.city}{record?.state&& record?.state!==""?", "+record.state:""}</span>)
    },
    {
      title: 'Post Status',
      dataIndex: 'poststatus',
      key: 'poststatus',
      width: '15%',
      sorter: (a, b) => a.poststatus - b.poststatus,
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      width: '15%',
      sorter: (a, b) => a.createdOn - b.createdOn,
      render: (text)=>(<span>{text?dayjs(text).format(constants.DateFormat2):""}</span>)
    },
    {
      title: 'Deleted On',
      dataIndex: 'deletedOn',
      key: 'deletedOn',
      width: '15%',
      sorter: (a, b) => a.deletedOn - b.deletedOn,
      render: (text)=>(<span>{text?dayjs(text).format(constants.DateFormat2):""}</span>)
    },
    {
      title: 'API Response',
      dataIndex: 'apiResponse',
      key: 'apiResponse',
      width: '15%',
      // sorter: (a, b) => a.deletedOn - b.deletedOn,
      render: (text) =>(
        <Popover content={<span className='break-words'>{text}</span>} placement='left' title='API Response' trigger="click" overlayClassName='w-full md:w-1/2'>
          <Button type='link'>Response</Button>
        </Popover>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      // width: '15%',
      hidden: !Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || !Cookies.get(constants.COOKIE_IS_ADMIN) === true,
      // sorter: (a, b) => a.deletedOn - b.deletedOn,
      render: (text,record) =>(
        // <Popover content={text} title='API Response' trigger="click">
        record.active?
        <Popconfirm 
          title="Repost Job" 
          placement='left'
          description="Do you want to repost?"  
          okText="Yes"
          cancelText="No"
          onConfirm={()=>deleteaJob(record.id)}
          >
          <Button type='link'>Repost</Button>
          </Popconfirm>:null
        // </Popover>
      )
    },
  ];
  // Table end

  return (
    <div>
      <div className="flex flex-wrap justify-between items-center gap-1">
        <Space>
          <div className="uppercase font-bold text-xl">Jobs Single Post : </div> <Image src={supplierLogoUrl} preview={false} width={100} />
        </Space>
      </div>
      <Divider className="my-3" />
      <div>
        <Space>
          <div  className="flex flex-wrap justify-between items-center gap-1 mb-6">
            <Select className='w-60'
              allowClear
              defaultValue=""
              onChange={(e) => handleSelectChange(e)}
              >
              <Option key='all' value=''>Show all</Option>
              <Option key='live' value='live'>Live Jobs</Option>
              <Option key='success' value='success'>Success</Option>
              <Option key='failure' value='failure'>Failure</Option>
            </Select>
            </div>
        <Form form={form}>
          <Form.Item name="searchField">
            <Search
              placeholder="Search"
              allowClear
              onSearch={(e) => searchJob(e)}
              onChange={(e) => { searchJob(e.target.value); setSearchedValue(e.target.value) }}
              enterButton
              className="w-full md:w-60"
            />
          </Form.Item>
        </Form>
        </Space>
      </div>
      <div className="overflow-x-auto">
        <Table
        loading={isLoading}
          size='small'
          rowKey="id"
          columns={singlePostColumns}
          dataSource={singlePostData}
          onChange={onChangeJobTable}
          pagination={{
            total: jobsCount,
            showSizeChanger: true,
            pageSizeOptions: ["500", "1000", "2000", "5000"],
            defaultPageSize: 5000,
            current: currentPage,
          }}
          footer={() => singlePostData.length>0?
             'Showing ' + ((currentPage - 1) * pageSize + 1) + ' to ' + ((currentPage - 1) * pageSize + singlePostData?.length) + ' of ' + jobsCount + ' entries'
            : 'Showing 0 entries'
          }
        />
      </div>
    </div>
  );
};

export default JobsSinglePost;