import React, { useEffect } from 'react'
import { Redirect, Route, useHistory  } from 'react-router-dom'
import { useAuth } from '../services/AuthService';
import constants from '../constants/constants';
import Cookies from 'js-cookie';
import MultiBudgetAdmin from '../views/sub-buyer-admin/MultiBudgetAdmin';
import YourJobsAll from '../views/YourJobsAll';
import MultiCandidateAdmin from '../views/sub-buyer-admin/MultiCandidateAdmin';
import DownloadFile from '../views/DownloadFile';
import MultiJobAmin from '../views/sub-buyer-admin/MultiJobAdmin';
import YourJobsContainer from '../views/jobs/YourJobsContainer';
import MultiCostCenter from '../views/sub-buyer-admin/MultiCostCenter';
/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export function RouteWithLayout({ layout, auth, component, path, ...rest }) {
    const{ isUserAuthed }=useAuth();
    const history = useHistory();
    useEffect(() => {
        //URL handled when the menu items change due to subBuyerAdmin
            if ((Cookies.get(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN) && Cookies.get(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN)?.toString()==="true" && ( path === '/' || path !== '/multi-budget-admin' && path !== '/multi-supplier-admin' && path !== '/multi-application-report'  && path !== '/multi-job-admin' && path!=='/multi-candidate-admin'  && path!=='/multi-budgets' && path!=='/multi-click-report' && path!=='/multi-dashboard'  && path!=='/multi-cost-center' && path!=='/multi-priority-filters' && path!=='/multi-priority-rules')) ||
            ( Cookies.get(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN) && Cookies.get(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN)?.toString()==="false" && ( path === '/' || path === '/multi-budget-admin' || path === '/multi-supplier-admin' || path === '/multi-application-report' || path === '/multi-job-admin' || path==='/multi-candidate-admin' || path==='/multi-budgets' || path==='/multi-click-report' || path==='/multi-dashboard' || path==='/multi-cost-center' || path==='/multi-priority-filters' || path==='/multi-priority-rules'))) {
                if(path!=='/DownloadFile' && path!=='/profile' &&  path!=='/activity-logs'){
                    history.replace("/");
                }
            }
      }, [path, history]);

    if (!isUserAuthed()){
        // return <Redirect to="/login" />
        history.replace("/login");
    }
    let selectedComponent = component;
    const cookieValue = Cookies.get(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN)?.toString();
    let loggedInBuyer=Cookies.get(constants.COOKIE_LOGGED_BUYER_NAME)?.toLowerCase();
    let SelectedBuyer=Cookies.get(constants.COOKIE_SELECTED_BUYER_NAME)?.toLowerCase();
    if (cookieValue === "true") {
        if(path === '/' || (path !== '/multi-budget-admin' && path !== '/multi-supplier-admin' && path !== '/multi-application-report' && path !== '/multi-job-admin' && path !=='/multi-candidate-admin'  && path !=='/multi-budgets' && path!=='/multi-click-report' && path!=='/multi-dashboard' && path!=='/multi-cost-center' && path!=='/multi-priority-filters' && path!=='/multi-priority-rules')){
            if(path==='/DownloadFile'|| path==='/profile' || path==='/activity-logs'){
                selectedComponent= component;
            }else{
                if(loggedInBuyer?.includes("hi admin")&&loggedInBuyer===SelectedBuyer){
                    selectedComponent = MultiCostCenter;
                }else{
                    selectedComponent = MultiJobAmin;
                }
            }
        }
    }else{
        if(path === '/' || path === '/multi-budget-admin' || path === '/multi-supplier-admin' || path === '/multi-application-report' || path === '/multi-job-admin' || path ==='/multi-candidate-admin' || path ==='/multi-budgets' || path==='/multi-click-report' || path==='/multi-dashboard' || path==='/multi-cost-center' || path==='/multi-priority-filters' || path==='/multi-priority-rules'){
            selectedComponent = YourJobsContainer;
        }
    }

  return (
      <Route
          {...rest}
          render={(props) =>
              React.createElement(
                  layout,
                  {
                      ...props,
                      ...rest
                  },
                  React.createElement(selectedComponent, {
                      ...props,
                      ...rest
                  })
              )
          }
      />
  );
}
