import { Space, Divider, Select, Button, Input, Table, Popconfirm, Tooltip, Modal, Form, Switch, message } from 'antd';
import constants from '../constants/constants';
import { EditOutlined,EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined,FileExcelOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import moment from 'moment';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, downloadFiles, getPriorityCount } from '../globalFunctions/GlobalFunctions';

const { Search, TextArea } = Input;
const { Option } = Select

const YourJobsAll = () => {
  //Variable declaration
  const [YourJobsData, setYourJobsData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multiPriorityUpdate, setMultiPriorityUpdate] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewJob, setIsNewJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const [searchedValue, setSearchedValue] = useState("");
  const [jobsCount, setJobsCount] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [countryLoader, setCountryLoader] = useState(false);
  const [sortedValue, setSorterValue] = useState({
    columnKey: "",
    order: 'descend'
  });
  const [hidePostCompany,setHidePostCompany]=useState(false);
  const [isSelfPosted, setIsSelfPosted] = useState(Cookies.get(constants.COOKIE_SELECTED_BUYER_SELF_POSTED));
  const [isEmailPost, setIsemailPost] = useState(Cookies.get(constants.COOKIE_EMAIL_POST));
  const [isClickPost,setIsClickPost]=useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priorityCountList,setPriorityCountList]=useState([]);
  const [priorityList,setPriorityList]=useState([]);
  const [priorityColorCode,setPriorityColorCode]=useState([]);
  const [priorityCheck,setPriorityCheck]=useState("f");
  const [HiShowPostCode,setHiShowPostCode]=useState(false);
  const [HiShowCountryCode,setHiShowCountryCode]=useState(false);
  const [HiShowState,setHiShowState]=useState(false);
  const [HiShowCompanyName,setHiShowCompanyName]=useState(false);
  const [botPost,setBotPost]=useState(false);
  const [apiPost,setAPiPost]=useState(false);
  const [emailPostUrl,setEmailPostUrl]=useState(false);
  const [formSearch] = Form.useForm();
  const [loader,setLoader]=useState(false);

  //Function on page load
  useEffect(() => {
    getBuyerPrioritylist();
    getBuyerDetails();
    checkParams();
  }, []);

  // check url parameters
  const checkParams= ()=>{
    const url = window.location.href;
    const urlArray =url?.split('?');
    const getParam=urlArray.length>1? urlArray[1]?.split('='):[];
    if(getParam.length>0){
      if(getParam[0]==='id'|| getParam[0]==='keywords'){
        handleSearch(getParam[1])
        formSearch.setFieldsValue({
          searchField:getParam[1]
        })
      }
    }
  };

  //Fetch the jobs upload history for the last 30 days 
  const yourJobsListFetch = async (priorityLevel=null,clickValue) => {
    setIsLoading(true);
    resetStateVariable();
    let url;
    if(clickValue){
      url = WebLinks.GetAllYourJobsListingClick + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    }else{
      url = WebLinks.GetAllYourJobsListing + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    }
    if (priorityLevel && priorityLevel !== "-1") {
      url += "&priorityLevel=" + priorityLevel;
    }
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setYourJobsData(response.resultObj.data);
      setJobsCount(response.resultObj.getCount);
      setHidePostCompany(response.resultObj?.jobBoard);
      setPriorityCountList(response.resultObj?.priorityCount||[])
      setHiShowCompanyName(response.resultObj?.hiShowCompanyName);
      setHiShowCountryCode(response.resultObj?.hiShowCountryCode);
      setHiShowPostCode(response.resultObj?.hiShowPostcode );
      setHiShowState(response.resultObj?.hiShowState);
      setBotPost(response.resultObj?.botPost);
      setAPiPost(response.resultObj?.apiPost);
      setEmailPostUrl(response.resultObj?.emailPost);
    } else {
      message.error(response?.resultObj?.message);
    }
    setIsLoading(false);
  };
  const getBuyerDetails = async () => {
    setLoader(true);
    const response = await APIUtils.apiCall('GET', WebLinks.GetBuyerDetails + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
    if (!response.isCatch && response.resultObj?.statusCode === "2000") {
      setIsClickPost(response.resultObj?.data?.clickPost);
      yourJobsListFetch(null,response.resultObj?.data?.clickPost);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  //Reset
  const resetStateVariable=()=>{
    setYourJobsData([]);
    setJobsCount(0);
    setHidePostCompany(false);
    setPriorityCountList([])
    setHiShowCompanyName(false);
    setHiShowCountryCode(false);
    setHiShowPostCode(false);
    setHiShowState(false);
    setBotPost(false);
    setAPiPost(false);
    setEmailPostUrl(false);
  };
  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setPriorityList(response.resultObj.data);
      let temppriorityColorCode=[];
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
      response?.resultObj?.data.forEach(element => {
        let priorityObj={
          value:element.priorityLevel.toString(),
          colorValue:response?.resultObj?.data?.length>5?GetCustomPriorityLevelIcons(element.priorityLevel):GetPriorityLevelColors(element.priorityLevel),
          customName:element.customName,
          prioritycount:getPriorityCount(element.priorityLevel,response?.resultObj?.priorityCount)
        }
        temppriorityColorCode.push(priorityObj);
        setPriorityColorCode(temppriorityColorCode);
      });
    } else {
      setPriorityList([]);
      setPriorityColorCode([]);
      message.error(response?.resultObj?.message);
    }
  };

  //Function to get the Priority Level to change multiple jobs.
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
  };

  //Function to change the Active button to cross or tick.
  const changeActive = async (rowKey, previousValue) => {
    let tbldata = [...YourJobsData];
    let index = tbldata.findIndex((row) => row.id === rowKey);
    if (index > -1) {
      if (tbldata[index].active === true) {
        tbldata[index].active = false;
      } else {
        tbldata[index].active = true;
      }
      setYourJobsData(tbldata);
    }
    //Api call to update in DB
    let activePayload = null;
    activePayload = {
      jobId: rowKey,
      active: !previousValue,
    }
    const activeResponse = await APIUtils.apiCall('PUT', WebLinks.PutActiveJob, JSON.stringify(activePayload));
    if (activeResponse.isCatch) {
      message.error(message.resultObj.message);
    }
  };

  //Function to Update priority levels
  const UpdatePriorityLevels = async (priorityLevel, selectedIds, multiple = false) => {
    setIsLoading(true);
    let priorityUpdatePayload = selectedIds;
    const priorityLevelResponse = await APIUtils.apiCall("PUT", WebLinks.PutUpdateSinglePriority + "buyerId=" + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&PriorityLevelEnd=" + priorityLevel +"&UpdatedByBuyerId="+Cookies.get(constants.COOKIE_LOGGED_BUYER_ID), JSON.stringify(priorityUpdatePayload));
    if (priorityLevelResponse.isCatch) {
      message.error(priorityLevelResponse.resultObj.message);
    } else {
      if (multiple) {
        let tempJobData = [...YourJobsData];
        selectedIds.map((selectedId,index)=>{
          let itemIndex = tempJobData.findIndex(item => item.id === selectedId);
          tempJobData[itemIndex].priority = parseInt(priorityLevel);
        })
        setYourJobsData(tempJobData);
      }
      message.success(priorityLevelResponse.resultObj.message);
    }
    setIsLoading(false);
  }

  //Function to change the Priority Level of an individual job.
  const singlePriorityChange = async (prioritySelected, id) => {
    let tempJobData = [...YourJobsData];
    let itemIndex = tempJobData.findIndex(item => item.id === id);
    tempJobData[itemIndex].priority = prioritySelected;
    setYourJobsData(tempJobData);
    let tempPriorityList = [];
    tempPriorityList.push(id);
    UpdatePriorityLevels(prioritySelected, tempPriorityList);
  };

  //Function to get the priority level for multi-select dropdown
  const allSelectedPriority = (selectedPriority) => {
    setMultiPriorityUpdate(selectedPriority);
  };

  //Function to change the Priority Level for multiple jobs.
  const multiplePriorityChange = () => {
    UpdatePriorityLevels(multiPriorityUpdate, selectedRowKeys, true);
  };

  //Function the to get the details of a job on click of Edit.
  const showEditModal = async (jobId) => {
    setIsNewJob(false)
    const response = await APIUtils.apiCall("GET", WebLinks.GetSelectedJobDetails + jobId);
    if (!response.isCatch && response.resultObj) {
      setSelectedJob(response.resultObj.data);
    }
    let values = response?.resultObj?.data
    form.setFieldsValue({
      title: values.title,
      reference: values.reference,
      companyName: values.companyName,
      //companyLogo: values.companyLogo,
      country: values.countryCode,
      address: values.address,
      place: values.place,
      county: values.county,
      postcode: values.postcode,
      salary: values.salary,
      priority: values.priority,
      description: values.description,
      fullTime: values.fullTime === true ? true : false,
      partTime: values.partTime === true ? true : false,
      contract: values.contract === true ? true : false,
      hourlyWork: values.hourlyWork === true ? true : false,
      applyEmail: values.applyEmail,
      jobUrl: values.jobUrl
    })
    setIsEditModalOpen(true);
  };

  //Function to open job detais in new tab
  const openJobDetails=(jobId)=>{
    window.open(` https://www.applygateway.com/apply?JobId=${jobId}`,"_blank");
  };

  //Function to delete job
  const deleteJob = async (jobId) => {
    const jobDeleteResponse = await APIUtils.apiCall("DELETE", WebLinks.GetSelectedJobDetails + jobId);
    if (!jobDeleteResponse.isCatch) {
      message.success(jobDeleteResponse.resultObj.message)
      // yourJobsListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      yourJobsListFetch(null,isClickPost);
    } else {
      message.error(jobDeleteResponse.resultObj.message);
    }
  }

  //Function to save edited job
  const saveJobEditDetails = async () => {
    const selectedCountry = form.getFieldValue('country')
    const selectedCountryObject = countryList.find(country => country.countryCode === selectedCountry);
    const selectedCountryName = selectedCountryObject ? selectedCountryObject.country : '';

    let formValues = {
      address: form.getFieldValue('address') || "",
      //companyLogo: form.getFieldValue('companyLogo') || "",
      country: selectedCountryName,
      countryCode: selectedCountry,
      county: form.getFieldValue('county') || "",
      postcode: form.getFieldValue('postcode') || "",
      place: form.getFieldValue('place') || "",
      contract: form.getFieldValue('contract') || false,
      fullTime: form.getFieldValue('fullTime') || false,
      hourlyWork: form.getFieldValue('hourlyWork') || false,
      partTime: form.getFieldValue('partTime') || false,
      // applyEmail: Cookies.get(constants.COOKIE_EMAIL_POST)==="true" || Cookies.get(constants.COOKIE_EMAIL_POST)==true?form.getFieldValue('applyEmail')||"":"" ,
      applyEmail:form.getFieldValue('applyEmail')||"" ,
      jobUrl: form.getFieldValue('jobUrl')||""
    }
    let method, url, body;
    if (isNewJob) {
      method = "POST";
      url = WebLinks.AddNewJob;
      //TODO: change the body accordingly
      body = JSON.stringify({ ...form.getFieldsValue(), place: form.getFieldValue('place'), ...formValues, buyerId: Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) });
    } else {
      method = "PUT";
      url = WebLinks.UpdateSelectedJob;
      body = JSON.stringify({ ...form.getFieldsValue(), id: selectedJob.id, ...formValues });
    }
    const response = await APIUtils.apiCall(method, url, body);
    if (!response.isCatch || response.resultObj.statusCode === "2000") {
      handleCancel();
      message.success(response.resultObj.message);
      // yourJobsListFetch(currentPage, pageSize, "", sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      yourJobsListFetch(null,isClickPost);
    } else {
      handleCancel();
      message.error(response?.resultObj?.message || constants.ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    const selectedCountry = form.getFieldValue('country')
    fetchCountryList()
    if (isEditModalOpen && !selectedCountry) {
      fetchBuyerCountry()
    }
  }, [isEditModalOpen]);

  const fetchCountryList = async () => {
    const response = await APIUtils.apiCall("GET", WebLinks.GetCountryList);
    if (!response.isCatch) {
      setCountryList(response.resultObj.data)
    } else {
      message.error(response.resultObj.message);
    }
  }

  const fetchBuyerCountry = async () => {
    setCountryLoader(true)
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)
    const response = await APIUtils.apiCall("GET", WebLinks.GetBuyerCountry + buyerId);
    if (!response.isCatch) {
      form.setFieldsValue({
        country: response.resultObj.data.countryCode
      });
      setCountryLoader(false)
    } else {
      setCountryLoader(false)
      message.error(response.resultObj.message);
    }
  }

  //Function on change of application table
  const onChangeJobTable = (pagination, filters, sorter) => {
    if (pagination) {
      if (pageSize !== pagination.pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pagination?.current);
      }
      setPageSize(pagination?.pageSize);
      // if (sorter) {
      //   setSorterValue(sorter);
      //   yourJobsListFetch(pagination?.current, pagination?.pageSize, searchedValue, sorter.order ? sorter.columnKey : "", sorter.order === "ascend" ? false : true);
      // } else {
      //   yourJobsListFetch(pagination?.current, pagination?.pageSize, searchedValue, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
      // }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEditModalOpen(false);
  };

  //Function on search
  const searchJob = (searchText) => {
    yourJobsListFetch(1, 100, searchText, sortedValue.columnKey, sortedValue.order === "ascend" ? false : true);
    setCurrentPage(1);
    setPageSize(25);
  }
  const handleSearch = (value) => {
    setSearchedValue(value);
  };

  const filterPriority = (value) => {
    yourJobsListFetch(value,isClickPost);
  }
const GetNoofDays =(apps,createdOn)=>{
  if (apps === 0) {
    return 0;
  }
  // Check if `createdOn` is a valid date
  const creationDate = new Date(createdOn);
  if (isNaN(creationDate)) {
    return 0; // Handle invalid date gracefully
  }
  const currentDate = new Date();
  const difference = currentDate - creationDate;
  if (difference === 0) {
    return apps; // Avoid division by zero
  }
  const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24))+1;
  // Ensure `apps` is a valid number before division
  if (isNaN(apps)) {
    return 0; // Handle invalid `apps` value gracefully
  }
  return apps / daysDifference;
};
  // Table columns
  const yourJobsColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      hidden: true
    },
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'active',
      align: 'center',
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? 1 : -1),
      render: (_, record) =>
        record.active === true ? (
          <Button
            icon={<CheckOutlined className="text-xl text-lime-500" />}
            type="text"
            onClick={() => changeActive(record.id, record.active)}
          />
        ) : (
          <Button
            icon={<CloseOutlined className="text-xl text-lime-500" style={{ color: "red" }} />}
            type="text"
            onClick={() => changeActive(record.id, record.active)}
          />
        ),
    },
    {
      title: 'Posted',
      dataIndex: 'posted',
      key: 'posted',
      sorter: (a, b) => moment(a.posted).valueOf() - moment(b.posted).valueOf(),
      render: (text) =>
      (
        <span className='whitespace-nowrap'>{moment(text).format(constants.DateFormat2)}</span>
      )
    },
    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      sorter: (a, b) => a.priority - b.priority,
      render: (_, record) => (
        <Select
          onChange={(e) => singlePriorityChange(e, record.id)}
          value={record.priority}
          className='w-14'
          options={priorityCheck==="t"?constants.PriorityConstantList2:constants.PriorityConstantList1}
          // options={priorityColorCode}
          // options={[
          //   {
          //     value: 0,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-white my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 1,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400 my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 2,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400 my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 3,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600 my-2"></div>
          //     ),
          //   },
          //   {
          //     value: 4,
          //     label: (
          //       <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600 my-2"></div>
          //     ),
          //   },
          // ]}
        />
      ),
    },
    {
      title: 'Apps',
      dataIndex: 'applicationCount',
      key: 'applicationCount',
      hidden: isClickPost,
      sorter: (a, b) => a.applicationCount - b.applicationCount,
    },
    {
      title: 'Apply Clicks',
      dataIndex: 'applyClicks',
      key: 'applyClicks',
      hidden: !isClickPost ,
      sorter: (a, b) => a.applyClicks - b.applyClicks,
    },
    // {
    //   title: '/Day',
    //   dataIndex: 'applicationCount',
    //   key: 'applicationCount',
    //   render: (_, record) => {
    //     const daysValue = GetNoofDays(record.applicationCount, record.posted);
    //     const formattedValue = Number.isInteger(daysValue) ? daysValue.toFixed(0) : daysValue.toFixed(2);
        
    //     return (
    //       <div>
    //         {formattedValue}
    //       </div>
    //     );
    //   },
    //   sorter: (a, b) => {
    //     const aValue = GetNoofDays(a.applicationCount, a.posted);
    //     const bValue = GetNoofDays(b.applicationCount, b.posted);

    //     return aValue - bValue; // You can adjust the comparison logic as needed
    //   },
      
    // },
    {
      title: 'Job Title',
      dataIndex: 'title',
      key: 'jobTitle',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      sorter: (a, b) =>{
        const referenceA = parseInt(a.reference.match(/\d+/)[0], 10);
        const referenceB = parseInt(b.reference.match(/\d+/)[0], 10);
    
        return referenceA - referenceB;
      },
    },
    {
      title: 'Location',
      dataIndex: 'place',
      key: 'place',
      sorter: (a, b) => a.place.localeCompare(b.place),
    },
    {
      title: 'State',
      dataIndex: 'county',
      key: 'county',
      hidden: !HiShowState,
      sorter: (a, b) => a.place.localeCompare(b.place),
    },
    {
      title: 'Postcode',
      dataIndex: 'postcode',
      key: 'postcode',
      hidden: !HiShowPostCode,
      sorter: (a, b) =>{
        if (a.postcode === '-') return -1; // '-' comes first
        if (b.postcode === '-') return 1; // '-' comes first
        return a.postcode.localeCompare(b.postcode);
      },
    },
    {
      title: 'Country',
      dataIndex: 'countryCode',
      key: 'countryCode',
      hidden: !HiShowCountryCode,
      //sorter: (a, b) => a.county - b.county,
      // render: (text,record) =>
      // (
      //   <span className='whitespace-nowrap'>{record?.countryCode!==null?text+" "+record?.countryCode:""}</span>
      // )
    },
    {
      title: 'countryCode',
      dataIndex: 'countryCode',
      key: 'countryCode',
      hidden: true,
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      hidden: !HiShowCompanyName,
      sorter: (a, b) => a?.companyName?.localeCompare(b?.companyName),
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit" zIndex='1000'>
            <Button size='small' type="link" icon={<EditOutlined />} onClick={() => showEditModal(record.id)} />
          </Tooltip>
          {isSelfPosted === "true" || isSelfPosted === true ?
          <>
          <Popconfirm
            title="Sure to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteJob(record.id)}
          >
            <Tooltip title="Delete" placement="bottom">
              <Button size='small' type="link" danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
          <Tooltip title="View">
            <Button size='small' type="link" icon={<EyeOutlined />} onClick={() => openJobDetails(record.id)} />
          </Tooltip>
          </>
          :
          <Tooltip title="View">
            <Button size='small' type="link" icon={<EyeOutlined />} onClick={() => openJobDetails(record.id)} />
          </Tooltip>
          }
        </Space>
      ),
    }
  ];
  // Table end
  const filteredDataSource = YourJobsData.filter((record) =>{
    if (yourJobsColumns.some((column) => {
      const dataIndex = column.dataIndex;
      if (dataIndex) {
        const cellValue = record[dataIndex];
        // Handle custom rendering for '/Day' column
        if (dataIndex === 'applicationCount' && column.title === '/Day') {
          const cellValueString = GetNoofDays(record.applicationCount, record.posted).toString().toLowerCase();
          if (cellValueString.includes(searchedValue.toLowerCase())) {
            return true;
          }
        }else if(dataIndex === 'id'){
          const ids = searchedValue.split(',').map(id => id?.trim()).filter(id => id !== '');
          // return ids.some(id => record[dataIndex].toString().toLowerCase().includes(id.toLowerCase()));
          if(ids.some(id => record[dataIndex].toString().toLowerCase().includes(id?.toLowerCase()))){
            return true;
          }else{
            return false;
          }
        } 
        else {
          // Handle standard columns
          const cellValueString = cellValue && cellValue.toString().toLowerCase();
          if (cellValueString && cellValueString.includes(searchedValue.toLowerCase())) {
            return true;
          }
        }
      }
      return false; // Skip undefined or null values
    })) {
      return true; // If any column has a match, include the record
    }
    return false; // Skip records with no matches in any column
  });

  // const getPriorityCount=(priorityValue,priorityCountListValues)=>{
  //   let filterPriority= priorityCountListValues?.filter((item)=>item.priorityLevel===priorityValue);
  //   if (filterPriority.length>0){
  //     return filterPriority[0].priorityCount;
  //   }else{
  //     return 0;
  //   }
  // }

	//Function to download as excel
	const downloadFile = () => {
		setLoader(true)
    if(isClickPost){
		  downloadFiles(WebLinks.DownloadYourJobReportClick+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&isMultiYourJobs=false","YourJobReport.xlsx");
    }else{
		  downloadFiles(WebLinks.DownloadYourJobReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&isMultiYourJobs=false","YourJobReport.xlsx");
    }
    setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

  return (
    <div>
      <div className="flex justify-end mb-3 text-xs">
            {/* <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
              <span>Organic [{getPriorityCount(0)}]</span>
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
              <span>Standard [{getPriorityCount(1)}]</span>
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
              <span>Extra [{getPriorityCount(2)}]</span>
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
              <span>Max (inc. Social) [{getPriorityCount(3)}]</span>
            </Space>
            <Space>
              <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
              <span>Critical (inc. Social) [{getPriorityCount(4)}]</span>
            </Space> */}
            <Space wrap>
            <label>
            <strong>{constants.PriorityLevels}</strong>
          </label>
            {priorityColorCode?.map((item=>{
              return(
                <Space>
                <div className={item.colorValue}></div>
                <span>{item.customName} [{item.prioritycount}]</span>
              </Space>
              )
            }))}
            </Space>
          </div>
      <div className="flex justify-between items-center gap-1">
        <Space>
          <div className="uppercase font-bold text-xl">Your Jobs</div>
          {isSelfPosted === "true" || isSelfPosted === true ?
            <>
              <Button type="primary" onClick={(e) => { setIsEditModalOpen(true); setIsNewJob(true) }}>Post a Job</Button>
            </>
            : null}
            <span>{jobsCount} Jobs Listed </span>
        </Space>
      </div>
      <Divider className="my-3" />
      <div className="flex flex-wrap justify-between items-center gap-1 mb-3">
        <Space wrap>
          <span>{constants.UpdatePriorityLevels}</span>
          <Space.Compact>
            <Select
              onChange={allSelectedPriority}
              placeholder="Select Priority Level"
              className="w-[65vw] md:w-40"
              // options={[
              //   {
              //     value: "0",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
              //         Organic
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "1",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
              //         Standard
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "2",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
              //         Extra
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "3",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
              //         Max (inc. Social Media)
              //       </Space>
              //     ),
              //   },
              //   {
              //     value: "4",
              //     label: (
              //       <Space>
              //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
              //         Critical (inc. Social Media)
              //       </Space>
              //     ),
              //   },
              // ]}
            >
              {priorityColorCode?.map((item=>{
                return(
              <Option value={item.value}>
                   <Space>
                     <div className={item.colorValue}></div>
                     {item.customName}
                   </Space>             
              </Option>
                )
              }))}
            </Select>
            <Button type="primary" onClick={() => multiplePriorityChange()}>Update</Button>
          </Space.Compact>
        </Space>
        <Select
          onChange={filterPriority}
          defaultValue={"-1"}
          placeholder="Please select"
          className="w-full md:w-60"
          // options={[
          //   {
          //     value: "-1",
          //     label: (
          //       <Space>
          //         Priority Filter - Show All
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "0",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
          //         Organic
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "1",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
          //         Standard
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "2",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
          //         Extra
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "3",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
          //         Max (inc. Social Media)
          //       </Space>
          //     ),
          //   },
          //   {
          //     value: "4",
          //     label: (
          //       <Space>
          //         <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
          //         Critical (inc. Social Media)
          //       </Space>
          //     ),
          //   },
          // ]}
        >
          <Option value="-1">
            <Space>
              Priority Filter - Show All
            </Space>
          </Option>
          {priorityColorCode?.map((item=>{
                return(
              <Option value={item.value}>
                   <Space>
                     <div className={item.colorValue}></div>
                     {item.customName}
                   </Space>             
              </Option>
                )
              }))}
        </Select>
        <Form
         form={formSearch}
        >
          <Form.Item name="searchField"  className='mb-0'>
            <Search
              placeholder="Search"
              allowClear
              onSearch={(e) => handleSearch(e)}
              onChange={(e) => { handleSearch(e.target.value);}}
              enterButton
              className="w-full md:w-60"
            />
          </Form.Item>
        </Form>
        <Tooltip placement='right' title="Download as Excel">
            <Button loading={loader}  onClick={() => downloadFile()} className="bg-green-800 text-white hover:!text-white hover:!border-green-500" icon={<FileExcelOutlined />}></Button>
        </Tooltip>
      </div>
      <div className="overflow-x-auto">
        <Table
          loading={isLoading}
          size='small'
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
            selectedRowKeys: selectedRowKeys
          }}
          rowKey="id"
          columns={yourJobsColumns}
          dataSource={filteredDataSource}
          // onChange={onChangeJobTable}
          pagination={{
            total: filteredDataSource?.length || 0,
            showSizeChanger: true,
            pageSizeOptions: ["200", "500", "1000", "2000"],
            defaultPageSize: 200,
            onChange: (page,pageSize) => {setCurrentPage(page); setPageSize(pageSize)},
            // current: currentPage,
          }}
          footer={(paginationData) =>
            `Showing ${Math.min(((currentPage - 1) * pageSize + 1), filteredDataSource?.length || 0)} to ${Math.min((currentPage * pageSize), filteredDataSource?.length || 0)} of ${filteredDataSource?.length || 0} entries`
          }
          // footer={(paginationData) =>`Showing ${Math.min(((currentPage - 1) * paginationData.pageSize + 1), jobsCount)} to ${paginationData.length} of ${jobsCount} entries`}
          //  
          // 'Showing ' + ((currentPage - 1) * pageSize + 1) + ' to ' + ((currentPage - 1) * pageSize + YourJobsData.length) + ' of ' + jobsCount + ' entries'}
        />
      </div>
      <Modal
        title={isNewJob?"Add Job Details":"Edit Job Details"}
        open={isEditModalOpen}
        onCancel={handleCancel}
        okText={<span className="capitalize">Save</span>}
        onOk={() => form.submit()}
        style={{ top: 20 }}
        width={800}
      >
        <Form form={form} name="form_in_modal" layout="vertical" onFinish={saveJobEditDetails}>
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
            <Form.Item
              name="title"
              label="Job Title"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}
              className="mb-0"
            >
              <Input />
            </Form.Item>
            <Form.Item name="reference" label="Reference" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="companyName" label="Company Name" className="mb-0"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              className="mb-0"
            >
              <Select loading={countryLoader}>
                {countryList.map((item) => (
                  <Option key={item.countryCode} value={item.countryCode}>{item.country}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="address" label="Address" className="mb-0">
              <TextArea rows={1} />
            </Form.Item>
            <Form.Item name="place" label="City/Town" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="county" label="County" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="postcode" label="Post code" className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="salary" label="Salary"
              // rules={[
              //   {
              //     required: true,
              //     message: "Required field",
              //   },
              // ]} 
              className="mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="priority" label="Priority"
              rules={[
                {
                  required: true,
                  message: "Required field",
                },
              ]} 
              className="mb-0">
              <Select
                className="w-full"
                options={[
                  {
                    value: 0,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-white"></div>
                        Organic
                      </Space>
                    ),
                  },
                  {
                    value: 1,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400"></div>
                        Standard
                      </Space>
                    ),
                  },
                  {
                    value: 2,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400"></div>
                        Extra
                      </Space>
                    ),
                  },
                  {
                    value: 3,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600"></div>
                        Max + Social
                      </Space>
                    ),
                  },
                  {
                    value: 4,
                    label: (
                      <Space>
                        <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600"></div>
                        Critical + Social
                      </Space>
                    ),
                  },
                ]}
              />
            </Form.Item>
            {/* {Cookies.get(constants.COOKIE_EMAIL_POST)==="true" || Cookies.get(constants.COOKIE_EMAIL_POST)==true?(
              <Form.Item
                name="applyEmail"
                label="Recruiters Email"
              >
                <Input />
              </Form.Item>
             ):null } */}
              { emailPostUrl === false && botPost === false && apiPost === false ?
              <Form.Item name="jobUrl" label="Application URL" className="mb-0">
               <Input />
             </Form.Item>
            :null}
             {isSelfPosted === "true" || isSelfPosted === true ?
            //  <>
            //  {isClickPost === "true" || isClickPost === true ?
            //  <Form.Item name="jobUrl" label="Application URL" className="mb-0">
            //   <Input />
            // </Form.Item>
            // :
            <>
              {isEmailPost==="true"|| isEmailPost===true?
              <Form.Item name="applyEmail" label="Application Email" className="mb-0">
              <Input />
              </Form.Item>:null
              }</>
            // }
            // </>
            :null}
          </div>
          <br/>
          <div className="grid xs:grid-cols-1 lg:grid-cols-4 gap-4">
            <Form.Item name="fullTime" label="Full Time" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No"
              />
            </Form.Item>
            <Form.Item name="partTime" label="Part Time" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="contract" label="Contract" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="hourlyWork" label="Hourly Work" valuePropName='checked'>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </div>
          <Form.Item name="description" label="Job Description"
            rules={[
              {
                required: true,
                message: "Required field",
              },
            ]}>
            <TextArea
              autoSize={{
                minRows: 6,
                maxRows: 12,
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default YourJobsAll;