import { Tabs, Divider } from 'antd';
import constants from '../../constants/constants';
import { CalendarOutlined, ProfileOutlined } from '@ant-design/icons';
import Budgets from '../Budgets';
import Spend from './Spend';
import { useLocation } from 'react-router-dom';
import BudgetsAdmin from '../admin/BudgetsAdmin';
import { useEffect } from 'react';
import { useState } from 'react';
import BudgetsMulti from './BugetMulti';
import BudgetSpendMulti from './BudgetSpendMulti';
import BudgetSpendByCostCenterMulti from './BudgetSpendByCostCenterMulti';
const { TabPane } = Tabs;

const BudgetContainerMulti = () => {
	return (
		<div>
      <div className='uppercase font-bold text-xl'>Multi Budgets</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="1">
			<TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.BudgetTab1}</span> }
        key={1}
      >
       <BudgetsMulti/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />{constants.BudgetTab2}</span> }
        key={2}
      >
        <BudgetSpendMulti/>
      </TabPane>
      <TabPane
        tab={ <span className='uppercase'><ProfileOutlined className='text-xl' />{constants.BudgetTab3}</span> }
        key={3}
      >
        <BudgetSpendByCostCenterMulti/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default BudgetContainerMulti;