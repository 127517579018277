import {Space, Table, message, Spin, Form, Select, Row, Col, Divider } from 'antd';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
const { Option } = Select;
const MultiCostCenter = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Cost Center',
			dataIndex: 'name',
			key: 'name',
      width: '35%',
			sorter: (a, b) => a.name.length - b.name.length,
			render: (_, record) => {
				return (
					<>
							<span>{record.name}</span>
					</>
				)

			}
		},
		{
			title: 'All Jobs',
			dataIndex: 'jobCount',
			key: 'jobCount',
			align: 'center',
      width: '15%',
			sorter: (a, b) => a.jobCount - b.jobCount,
		},
		{
			title:<div className="w-3.5 h-3.5 border rounded-full border-black bg-white mx-auto"></div>,
			dataIndex: 'priority0',
			key: 'priority0',
			align: 'center',
			sorter: (a, b) => a.priority0 - b.priority0,
			render: (text) => <span className='mr-4'>{text}</span>,
		},
		{
			title: <div className="w-3.5 h-3.5 border rounded-full border-black bg-lime-400 mx-auto"></div>,
			dataIndex: 'priority1',
			key: 'priority1',
			align: 'center',
			sorter: (a, b) => a.priority1 - b.priority1,
			render: (text) => <span className='mr-4'>{text}</span>,
		},
		{
			title:  <div className="w-3.5 h-3.5 border rounded-full border-black bg-orange-400 mx-auto"></div>,
			dataIndex: 'priority2',
			key: 'priority2',
			align: 'center',
			sorter: (a, b) => a.priority2 - b.priority2,
			render: (text) => <span className='mr-4'>{text}</span>,
		},
		{
			title: <div className="w-3.5 h-3.5 border rounded-full border-black bg-red-600 mx-auto"></div>,
			dataIndex: 'priority3',
			key: 'priority3',
			align: 'center',
			sorter: (a, b) => a.priority3 - b.priority3,
			render: (text) => <span className='mr-4'>{text}</span>,
		},
		{
			title: <div className="w-3.5 h-3.5 border rounded-full border-black bg-purple-600 mx-auto"></div>,
			dataIndex: 'priority4',
			key: 'priority4',
			align: 'center',
			sorter: (a, b) => a.priority4 - b.priority4,
			render: (text) => <span className='mr-4'>{text}</span>,
		},
	];
	const [loader, setLoader] = useState(false);
	const [form] = Form.useForm();
  const [allowedSupplierList, setAllowedSupplierList] = useState([]);
  const [suplierListLoader,setSuplierListLoader]= useState(false);
  const [multiCostCenterData,setMultiCostcenterData]=useState([]);
  const [supplierids,setSupplierIds]= useState("");
	const [reportTotal, setReportTotal] = useState([]);

	useEffect(() => {
		getAllowedSuppliers();
		GetMultiCostCenterData();
	}, []);

	//Functions
	//Function to Month report data
	const GetMultiCostCenterData = async (supplierId="") => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetCostCenter + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "&supplierId="+supplierId);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setReportTotal(response.resultObj.data.pop());
			setMultiCostcenterData(response.resultObj.data);
			setLoader(false);
		} else {
			setMultiCostcenterData([]);
			setLoader(false);
		}
	}

	//Function on change of supplier selection
	const handleSupplierSelect=(selectedSuppliers)=>{
		if(selectedSuppliers&&selectedSuppliers!== 'showall'){
		//	let tempSelectedSuppliers=selectedSuppliers.filter(value => value !== 'showall');
			//let selectedSuppliersFinal=tempSelectedSuppliers.length>0?tempSelectedSuppliers:"";
			// form.setFieldsValue({
			// 	supplierList:selectedSuppliersFinal
			// })
		//	setSupplierIds(selectedSuppliersFinal);
			GetMultiCostCenterData(selectedSuppliers);
		}else{
			form.setFieldsValue({
				supplierList:"showall"
			})
			//setSupplierIds("");
			GetMultiCostCenterData();
		}
	}

	//Function to get Allowed suppliers list
	const getAllowedSuppliers =async()=>{
		setSuplierListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetALlAllowedSuppliers);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedSupplierList(response?.resultObj?.data||[])
			setSuplierListLoader(false);
		}else{
			setAllowedSupplierList([]);
			setSuplierListLoader(false);
			message.error(response.resultObj.message);
		}
	}

		//Function to display the month total in the table
	const ReportSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='left'>
						<strong>Totals</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{reportTotal.jobCount}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{reportTotal.priority0}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{reportTotal.priority1}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong>{reportTotal.priority2}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{reportTotal.priority3}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong>{reportTotal.priority4}</strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	return (
		<>
			<Spin spinning={loader}>
				<div>
          <div className='uppercase font-bold text-xl'>Multi Cost Center</div>
          <Divider className='mt-2 mb-3' />
					<div className='flex justify-between items-center mb-3'>
						<Space wrap>
							<Form form={form}>
							<Row gutter={[12, 12]}>
								<Col>
								<Space wrap>
								<label>Filter by Supplier</label>
								<Form.Item name="supplierList" className='mb-0'>
									<Select
										loading={suplierListLoader}
										className='!w-72'
										placeholder={constants.supplierName}
										//mode='multiple'
										allowClear
										defaultValue={"showall"}
										showSearch
									filterOption={(input, option) =>
											option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
										onChange={(e) => handleSupplierSelect(e)}
										>
											<Option key='showall' value='showall'>All Jobs</Option>
												{allowedSupplierList?.map((supplier) => (
													<Option key={supplier.supplierId}
															value={supplier.supplierId}
													>
													{supplier.name}</Option>
										))}
									</Select>
								</Form.Item>
								</Space>
                </Col>
								<Col></Col>
								</Row>
							</Form>
						</Space>
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							dataSource={multiCostCenterData}
							scroll={{
								x: 950,
							}}
							sortDirections={['descend', 'ascend', 'descend']}
							pagination={{
								defaultPageSize: 100,
							}}
							summary={() => ReportSummary()}
						/>
					</div>
				</div>
			</Spin>
		</>
	);
};

export default MultiCostCenter;