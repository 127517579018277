import { Button, message, Spin, Result } from 'antd';
import { useState, useEffect } from 'react';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import constants from '../constants/constants';
import Cookies from 'js-cookie';

const DownloadFile = (props) => {
    const cookieValue = Cookies.get(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN)?.toString();
    const buyerId = Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) 

    //Variable declaration
    const [showError, setShowError] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        fetchCVDetail()
    }, [])

    const fetchCVDetail = async () => {
        setLoader(true)
        const buyer= cookieValue==="true"?props.location.state.buyerId:buyerId;
        const cvId = props.location.state.cVid
        const userId = props.location.state.userId
        const url = WebLinks.GetCandidateCVDetail + `?buyerId=${buyer}&CvId=${cvId}&UserId=${userId}`;
        const response = await APIUtils.apiCall('GET', url);
        if (!response.isCatch && response.resultObj.statusCode === "2000") {
            downloadCV(response.resultObj.data)
            // setLoader(false);
        } else {
            setLoader(false);
            setShowError(response.resultObj.message)
        }
    }

    const downloadCV = async (data) => {
        setLoader(true)
        const apiKey = process.env.REACT_APP_CV_DOWNLOAD_API_KEY
        const url = `https://cvfilemanager.applygateway.com/v1/cv/download?apiKey=${apiKey}&fileName=${data.fileName}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.cvFileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoader(false)
                message.success('File downloaded successfully');
            })
            .catch(error => {
                setLoader(false)
                message.error('Error downloading file');
            });
    }

    const goToCandidates = () => {
        if(cookieValue==="true"){
            props.history.push({
                pathname: '/multi-candidate-admin',
                state: {
                    state: props.location.state
                }
            });
        }else{
            props.history.push({
                pathname: '/candidates',
                state: {
                    state: props.location.state
                }
            });
        }
    }


    return (
        <Spin spinning={loader}>
            {showError && <Result
                status="error"
                title={showError}
            ></Result>}
            <Button type="primary" key="goBack" onClick={goToCandidates}>Go back</Button>

        </Spin>
    );
};

export default DownloadFile;