import { DatePicker, Space, Select, Table, message, Spin, Form, Image, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import { FileExcelOutlined } from '@ant-design/icons';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import { downloadFiles } from '../../globalFunctions/GlobalFunctions';
const { RangePicker } = DatePicker;

const MonthReport = (props) => {

	//Variable declaration
	const columns = [
		{
			title: 'Month/Supplier',
			dataIndex: 'supplierName',
			key: 'supplierName',
			sorter: (a, b) => a.supplierName.length - b.supplierName.length,
			render: (_, record) => {
				return (
					<>
						{record.logoURL && record.logoURL !== "" ?
							<Image src={record.logoURL} preview={false} width={100} /> :
							<span>{record.supplierName}</span>
						}
					</>
				)

			}
		},
		{
			title: 'Applies',
			dataIndex: 'applies',
			key: 'applies',
			align: 'center',
			sorter: (a, b) => a.applies - b.applies,
		},
		{
			title: 'Cost',
			dataIndex: 'totalCost',
			key: 'totalCost',
			align: 'center',
			sorter: (a, b) => a.totalCost - b.totalCost,
		},
		{
			title: 'Cost/Apply',
			dataIndex: 'totalCostApplies',
			key: 'totalCostApplies',
			align: 'center',
			sorter: (a, b) => a.totalCostApplies - b.totalCostApplies,
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			align: 'center',
			sorter: (a, b) => a.quality - b.quality,
			render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.quality + " "}</span><span>({record.qualityPer}%)</span>
					</Space>
				)

			}
		},
		{
			title: 'Cost/Quality',
			dataIndex: 'costQuality',
			key: 'costQuality',
			align: 'center',
			sorter: (a, b) => a.costQuality - b.costQuality,
		},
		{
			title: 'Interviews',
			dataIndex: 'interview',
			key: 'interview',
			align: 'center',
			sorter: (a, b) => a.interview - b.interview,
			render: (_, record) => {
				return (
					<>
						<span>{record.interview + " "}</span><span>({record.interviewPer}%)</span>
					</>
				)

			}
		},
		{
			title: 'Cost/Interview',
			dataIndex: 'costInterview',
			key: 'costInterview',
			align: 'center',
			sorter: (a, b) => a.costInterview - b.costInterview,
		},
		{
			title: 'Hires',
			dataIndex: 'hired',
			key: 'hired',
			align: 'center',
			sorter: (a, b) => a.hired - b.hired,
			render: (_, record) => {
				return (
					<Space size={3}>
						<span>{record.hired + " "}</span><span>({record.hiredPer}%)</span>
					</Space>
				)

			}
		},
		{
			title: 'Cost/Hire',
			dataIndex: 'costHired',
			key: 'costHired',
			align: 'center',
			sorter: (a, b) => a.costHired - b.costHired,
		}
	];
	const [monthlyData, setMonthlyData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [loader, setLoader] = useState(false);
	const [totalMonthCount, setTotalMonthCount] = useState(0);
	const [monthReportTotal, setMonthReportTotal] = useState([]);
	const [form] = Form.useForm();

	//On load function call
	// useEffect(() => {
	// 	// if (props && props.dateSelectedValue) {
	// 	// 	form.setFieldsValue({
	// 	// 		rangePicker: [dayjs(props.dateSelectedValue[0]), dayjs(props.dateSelectedValue[1])],
	// 	// 	})
	// 	// } else {
	// 	// 	form.setFieldsValue({
	// 	// 		rangePicker: [dayjs(), dayjs()],
	// 	// 	})
	// 	// }
	// 	let date1 = ''
	// 	let date2 = ''
	// 	let isMounted = true;
	// 	if (props && props.dateSelectedValue) {
	// 		setRangePickerValue([
	// 			dayjs(props.dateSelectedValue[0]),
	// 			dayjs(props.dateSelectedValue[1]),
	// 		]);
	// 		date1 = dayjs(props.dateSelectedValue[0]);
	// 		date2 = dayjs(props.dateSelectedValue[1]);
	// 		form.setFieldsValue({
	// 			rangePicker: [
	// 				dayjs(props.dateSelectedValue[0]),
	// 				dayjs(props.dateSelectedValue[1]),
	// 			],
	// 		});
	// 	} else {
	// 		console.log("dateSelectedValuenot")
	// 		form.setFieldsValue({
	// 			rangePicker: [
	// 				dayjs().startOf('month'),
	// 				dayjs().endOf('month'),
	// 			],
	// 		});
	// 		date1 = dayjs().startOf('month')
	// 		date2 = dayjs().endOf('month')
	// 	}
	// 	setCurrentPage(1);
	// 	setPageSize(25);

	// 	let timer;
	// 	timer = setTimeout(() => {
	// 		if (isMounted) {
	// 			setCurrentPage(1);
	// 			setPageSize(25);
	// 			GetMonthlyReportData(1, 25,
	// 				date1.format(constants.MonthFormat),
	// 				date2.format(constants.MonthFormat));

	// 		}
	// 	}, 500);
	// 	return () => {
	// 		clearTimeout(timer);
	// 		isMounted = false;
	// 	};
	// 	//setRangePickerValue(props && props.dateSelectedValue ? [dayjs(props.dateSelectedValue[0]).format(constants.MonthFormat), dayjs(props.dateSelectedValue[1]).format(constants.MonthFormat)] : [dayjs().format(constants.MonthFormat), dayjs().format(constants.MonthFormat)]);

	// }, [props, rangePickerValue, form]);
	useEffect(() => {
		const date1 = dayjs().startOf('month')
		const date2 = dayjs().endOf('month')
		form.setFieldsValue({
			rangePicker: [
				date1,
				date2,
			],
		});
		setRangePickerValue([
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat),
		]);
		GetMonthlyReportData(1, 25,
			date1.format(constants.MonthFormat),
			date2.format(constants.MonthFormat));
	}, []);


	//Functions
	//Function runs when range picker value is changed
	const RangePickerChanged = debounce((_, dateStrings) => {
		console.log("datepicker", _, dateStrings)
		props.changeDate(dateStrings);
		setRangePickerValue([
			dayjs(dateStrings[0], constants.MonthFormat),
			dayjs(dateStrings[1], constants.MonthFormat),
		]);
		GetMonthlyReportData(currentPage, pageSize, dateStrings[0], dateStrings[1]);
	}, 500);

	//Function to Month report data
	const GetMonthlyReportData = async (currentPage, pazeSize, startDate, endDate) => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetApplicationReport + "/" + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + "?fromData=" + startDate + "&toDate=" + endDate + "&limit=" + pazeSize + "&pageNo=" + currentPage);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setMonthReportTotal(response.resultObj.data.monthReport.pop());
			setMonthlyData(response.resultObj.data.monthReport);
			setTotalMonthCount(response.resultObj.data.monthReportCount);
			setLoader(false);
		} else {
			setMonthlyData([]);
			setLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function on change of application table
	const onchangeApplicationTable = (pagination, filters, sorter) => {
		if (pagination) {
			if (pageSize !== pagination.pageSize) {
				setCurrentPage(1);
			} else {
				setCurrentPage(pagination?.current);
			}
			setPageSize(pagination?.pageSize);
			GetMonthlyReportData(pagination?.current, pagination?.pageSize, rangePickerValue[0], rangePickerValue[1])
		}
	};

	//Function to download as excel
	const downloadFile = () => {
		setLoader(true)
		downloadFiles(WebLinks.DownloadApplicationMonthReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID) + `&fromDate=${dayjs(rangePickerValue[0]).format(constants.MonthFormat)}&toDate=${dayjs(rangePickerValue[1]).format(constants.MonthFormat)}`,"ApplicationMonthReport.xlsx");
		setTimeout(() => {
				setLoader(false)
		}, 3000);
	};

	//Function to display the month total in the table
	const monthReportSummary = () => {
		return (
			<>
				<Table.Summary.Row className='bg-teal-50'>
					<Table.Summary.Cell index={0} align='center'>
						<strong>Totals</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={1} className='text-center'>
						<strong>{monthReportTotal.applies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2} className='text-center'>
						<strong>{monthReportTotal.totalCost}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3} className='text-center'>
						<strong>{monthReportTotal.totalCostApplies}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.quality + " "}<span>({monthReportTotal.qualityPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5} className='text-center'>
						<strong>{monthReportTotal.costQuality}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.interview + " "}<span>({monthReportTotal.interviewPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={7} className='text-center'>
						<strong>{monthReportTotal.costInterview}</strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8} className='text-center'>
						<strong><Space size={3}>{monthReportTotal.hired + " "}<span>({monthReportTotal.hiredPer}%)</span></Space></strong>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={9} className='text-center'>
						<strong>{monthReportTotal.costHired}</strong>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		)
	};

	return (
		<>
			<Spin spinning={loader}>
				<div>
					<div className='flex flex-wrap gap-3 justify-between items-center mb-3'>
						<Form form={form}>
							<Form.Item name="rangePicker" className='mb-0'>
								<RangePicker picker="month"
									placeholder={["From", "To"]}
									onChange={RangePickerChanged}
									//value={rangePickerValue}

									format={constants.MonthFormat} />
							</Form.Item>
						</Form>
						{/* <Space wrap>
						<label>Filter by Occupation</label>
						<Select
							className='w-72'
							mode="multiple"
							allowClear
							maxTagCount='responsive'
							defaultValue="allOccupation"
							options={[
								{
									value: 'allOccupation',
									label: 'Show All',
								},
								// {
								// 	value: 'Nurses',
								// 	label: 'Nurses',
								// },
							]}
						/>
					</Space> */}
          <Tooltip placement='right' title="Download as Excel">
              <Button
                  className='bg-green-800 text-white hover:!text-white hover:!border-green-500'
                  icon={<FileExcelOutlined />}
                  onClick={()=>{downloadFile()}}
              >
                Download as Excel
              </Button>
          </Tooltip>
					</div>
					<div className='overflow-x-auto'>
						<Table
							columns={columns}
							dataSource={monthlyData}
							scroll={{
								x: 1000,
							}}
							// onChange ={onchangeApplicationTable}
							sortDirections={['descend', 'ascend', 'descend']}
							pagination={{
								total: totalMonthCount,
								// showSizeChanger: true,
								// pageSizeOptions: ["25", "50", "100"],
								defaultPageSize: 100,
								// current: currentPage,
							}}
							summary={() => monthReportSummary()}
						/>
					</div>
				</div>
			</Spin>
		</>
	);
};

export default MonthReport;