import { DatePicker, Space, Table, message, Spin, Form,Select, Row, Col} from 'antd';
import dayjs from 'dayjs';
import constants from '../../constants/constants';
import { useEffect, useState } from 'react';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import APIUtils from '../../api/APIUtils';
import moment from 'moment';
const { Option } = Select;

const generateDayColumns = (daysInMonth) => {
	return daysInMonth.map((day) => ({
	  title: `${day}`,
	  dataIndex: `buyerDayReport.${day}`,
	  key: `day${day}`,
	  render: (text, record) => {
		const report = record.buyerDayReport.find((report) => report.day === day);
		return report ? `${report.totalApps}/${report.successApps}` : '-';
	  },
	}));
  };
const DayReport = (props) => {

	//Variable declaration
	const daysInMonth = Array.from({ length: 31 }, (_, index) => index + 1);
	const dayColumns = generateDayColumns(daysInMonth);

	const columns = [
	  {
		title: 'Buyer',
		dataIndex: 'buyerName',
		key: 'buyerName',
		fixed: 'left',
		sorter: (a, b) => a.buyerName.localeCompare(b.buyerName)
	  },
	  {
		title: 'Days of Month  ( Total Apps / Completed )',
		children: dayColumns,
		className: 'days-column',
	  },
	];
	// const columns = [
	// 	{
	// 	  title: 'Buyer',
	// 	  dataIndex: 'buyerName',
	// 	  key: 'buyerName',
	// 	},
	// 	...daysInMonth.map((day) => ({
	// 	  title: `${day}`,
	// 	  dataIndex: 'buyerDayReport',
	// 	  key: `day${day}`,
	// 	  render: (buyerDayReport) => {
	// 		const report = buyerDayReport.find((report) => report.day === day);
	// 		return report ? `${report.totalApps}/${report.successApps}` : '-';
	// 	  },
	// 	})),
	//   ];
	const [monthlyData,setMonthlyData]=useState([]);
	const [loader,setLoader]=useState(false);
	const [form] = Form.useForm();
	const [buyerListLoader,setBuyerListLoader]= useState(false);
	const [allowedBuyerList, setAllowedBuyerList] = useState([]);
	const [rangePickerValue, setRangePickerValue] = useState([]);
	const [suplierListLoader,setSuplierListLoader]= useState(false);
	const [allowedSupplierList, setAllowedSupplierList] = useState([]);
	const [selectedBuyerIds,setSelectedBuyerIds]=useState([]);
	const [selectedSupplierIds,setSelectedsupplierIds]=useState([]);

	//On load function call
	useEffect(()=>{
		form.setFieldsValue({
			buyerList:["showall"],
			supplierList:["showall"]
		})
		getAllowedSuppliers();
		getAllowedBuyers();
		setRangePickerValue(dayjs());
		GetMonthlyReportData(moment().month() + 1,moment().year());
	},[props]);

	//Functions
	//Function runs when range picker value is changed
	const RangePickerChanged= (date, dateString) => {
		const currentDate = dayjs(date,constants.DateFormat);
		// const monthInteger = currentDate.month()+1;
		setRangePickerValue(currentDate);
		form.setFieldsValue({
			buyerList:["showall"],
			supplierList:["showall"]
		})
		GetMonthlyReportData(currentDate.month()+1,currentDate.year());
	};
	//Function to get the month report for the multiApplicationReport / subBuyers
	//this fn will only be called if the props.tabName is monthReport 
	const GetMonthlyReportData = async (month,year,buyerIds="",supplierIds="")=>{
		setLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetSubBuyerDailyReport+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&month="+month+"&year="+year+"&selectedBuyerId="+buyerIds+"&selectedSupplierId="+supplierIds);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
				// setMonthReportTotal(response.resultObj.data.monthReport.pop());
				setMonthlyData(response.resultObj.data);
		}else{
				setMonthlyData([]);
				// message.error(response.resultObj.message);
		}
		setLoader(false);
	};

	//Function to get Allowed suppliers list
	const getAllowedBuyers =async()=>{
		setBuyerListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GEtSubBuyerAllotedList+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedBuyerList(response?.resultObj?.data||[])
			setBuyerListLoader(false);
		}else{
			setAllowedBuyerList([]);
			setBuyerListLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function to get Allowed suppliers list
	const getAllowedSuppliers =async()=>{
		setSuplierListLoader(true);
		const response = await APIUtils.apiCall('GET',WebLinks.GetALlAllowedSuppliers);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			setAllowedSupplierList(response?.resultObj?.data||[])
			setSuplierListLoader(false);
		}else{
			setAllowedSupplierList([]);
			setSuplierListLoader(false);
			message.error(response.resultObj.message);
		}
	}

	//Function on change of supplier selection
	const handleSupplierSelect=(selectedSuppliers)=>{
		if(selectedSuppliers.length>0){
			let tempSelectedSuppliers=selectedSuppliers.filter(value => value !== 'showall');
			let selectedSuppliersFinal=tempSelectedSuppliers.length>0?tempSelectedSuppliers:"";
			form.setFieldsValue({
				supplierList:selectedSuppliersFinal
			})
			setSelectedsupplierIds(selectedSuppliersFinal);
			GetMonthlyReportData(rangePickerValue.month()+1,rangePickerValue.year(),selectedBuyerIds.length>0?selectedBuyerIds:"",selectedSuppliersFinal);
		}else{
			form.setFieldsValue({
				supplierList:["showall"]
			})
			setSelectedsupplierIds([]);
			GetMonthlyReportData(rangePickerValue.month()+1,rangePickerValue.year(),selectedBuyerIds.length>0?selectedBuyerIds:"","");
		}
	}

	//Function on change of supplier selection
	const handleBuyerSelect=(selectedBuyers)=>{
		if(selectedBuyers.length>0){
			let tempSelectedBuyers=selectedBuyers.filter(value => value !== 'showall');
			let selectedBuyerFinal=tempSelectedBuyers.length>0?tempSelectedBuyers:"";
			form.setFieldsValue({
				buyerList:selectedBuyerFinal
			})
			setSelectedBuyerIds(selectedBuyerFinal);
			GetMonthlyReportData(rangePickerValue.month()+1,rangePickerValue.year(),selectedBuyerFinal,selectedSupplierIds.length>0?selectedSupplierIds:"");
		}else{
			form.setFieldsValue({
				buyerList:["showall"]
			})
			GetMonthlyReportData(rangePickerValue.month()+1,rangePickerValue.year(),"",selectedSupplierIds.length>0?selectedSupplierIds:"");
		}
	};

	return (
		<>
		<Spin spinning={loader}>
			<div>
				<div className='flex justify-between items-center mb-3'>
					<Space wrap>
						<Form form={form} initialValues={{datePicker:dayjs()}}>
						<Row gutter={[12, 12]}>
							<Col>
							<Form.Item name="datePicker" className='mb-0'>
								<DatePicker format={constants.MonthFormat} onChange={RangePickerChanged} picker="month" />
							</Form.Item>
							</Col>
							<Col>
							<Space wrap>
								<label>Filter by Buyer</label>
								<Form.Item name="buyerList" className='mb-0'>
									<Select
										loading={buyerListLoader}
										className='!w-72'
										// value={selectedSupplier || []}
										placeholder={"Buyer"}
										mode='multiple'
										allowClear
										defaultValue={"showall"}
										showSearch
									filterOption={(input, option) =>
											option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
										onChange={(e) => handleBuyerSelect(e)}
										>
											<Option key='showall' value='showall'>Show all</Option>
												{allowedBuyerList?.map((buyer) => (
													<Option key={buyer.subPartnerId}
															value={buyer.subPartnerId}
													>
													{buyer.name}</Option>
										))}
									</Select>
								</Form.Item>
								<label>Filter by Supplier</label>
									<Form.Item name="supplierList" className='mb-0'>
										<Select
											loading={suplierListLoader}
											className='!w-72'
											// value={selectedSupplier || []}
											placeholder={constants.supplierName}
											mode='multiple'
											allowClear
											defaultValue={"showall"}
											showSearch
										filterOption={(input, option) =>
												option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
											onChange={(e) => handleSupplierSelect(e)}
											>
												<Option key='showall' value='showall'>Show all</Option>
													{allowedSupplierList?.map((supplier) => (
														<Option key={supplier.supplierId}
																value={supplier.supplierId}
														>
														{supplier.name}</Option>
											))}
										</Select>
									</Form.Item>
								</Space>
							</Col>
						</Row>
						</Form>
					</Space>
				</div>
				<div className='overflow-x-auto'>
					<Table
					className='day-report'
						columns={columns}
						dataSource={monthlyData}
						// scroll={{
						// 	x: 1000,
						// }}
						scroll={{ x: true, y: 500 }}
						rowKey={(record) => record.buyerName}
						sortDirections={['descend','ascend','descend']}
						pagination={false}
						// summary={() => monthReportSummary()}
					/>
				</div>
			</div>
		</Spin>
		</>
	);
};

export default DayReport;