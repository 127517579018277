import { Card, Col, Row, Space, Button, Avatar, Typography, Image, Pagination, Select, Divider, message, Empty, Spin, } from 'antd';
import { CheckCircleFilled, SettingOutlined, CloseCircleOutlined, EllipsisOutlined, FacebookFilled, InstagramOutlined, PauseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import constants from '../constants/constants';
import WebLinks from '../api/WebLinks';
import APIUtils from '../api/APIUtils';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { CurrencyContext } from '../Context';

const { Paragraph } = Typography;

const SocialMediaPromo = () => {
	const currencyContext=useContext(CurrencyContext);
	const [Loading,setLoading]=useState(false);
	const [data,setData]=useState([]);
	const [total,setTotal]=useState(0);
	const [currentPage,setCurrentPage]=useState(1);
	const [budget,setBudget]=useState('allBudget');
	const [jobStatus,setJobStatus]=useState(constants.ActiveJS);
	const [pageSize,setPageSize]=useState(constants.defaultSocialPagesize);
	const history = useHistory();
	const socialLogin =async()=>{
		const payload = {
			email:process.env.REACT_APP_SOCIAL_LOGIN,
			password:process.env.REACT_APP_SOCIAL_PASS }
		const response = await APIUtils.apiCall('POST',WebLinks.SocialMediaLogin,JSON.stringify(payload));
		if(!response.isCatch && response.resultObj.success){
			Cookies.set(constants.COOKIE_SOCIAL_TOKEN, response.resultObj?.data?.token, {path:'/',expires:constants.COOKIE_EXPIRY_DAYS});
		}else{
			message.error(constants.ERROR_MESSAGE);
		}
	};
	const getJobAD = async(TablePageSize = pageSize,currentPagenum=currentPage,socialJobStatus=jobStatus, Budget = budget) =>{
		setLoading(true);
		const SocialAPIToken = Cookies.get(constants.COOKIE_SOCIAL_TOKEN);
		if(SocialAPIToken!==undefined){
			// &location=london&title=manager&dateFrom=2022-07-21&withError=
			let url=WebLinks.GetJobAd+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);
			if(socialJobStatus){
				switch (socialJobStatus) {
					case constants.ActiveJS: url+="&active="+true;
						break;
					case constants.InActiveJS: url+="&inactive="+true+"&expired="+true;
						break;
					case constants.ErrorJS: url+="&error="+true;
						break;
					default:
						break;
				}
			}
			switch (Budget) {
				case constants.SpendingBudget:url+="&budgetSpending="+true;
					break;
				case constants.SpentBudget:url+="&budgetSpent="+true;
					break;
				case constants.NotLiveBudget:url+="&budgetNotLive="+true;
					break;
				default:url+="&budgetSpending="+true+"&budgetSpent="+true+"&budgetNotLive="+true;
					break;
			}
			 
			const response = await APIUtils.apiCall('GET',url+"&page="+currentPagenum+"&perPage="+TablePageSize,null,true,true);
			if(!response.isCatch && response.resultObj.success){
				//Added unique key to fix all inner tables opening together
				response.resultObj.data?.jobs?.map((item,index)=>{
					item.key=index;
				});
				setData(response.resultObj.data?.jobs);
				setTotal(response.resultObj.data.data.total);
			}else{
				setData([]);
				message.error(response.resultObj.message);
			}
		}
		setLoading(false);
	}; 
	const getData = async() =>{
		setLoading(true);
		let socialLoginToken = Cookies.get(constants.COOKIE_SOCIAL_TOKEN);
		if(socialLoginToken===undefined){
			await socialLogin();
		}
		await getJobAD();
		setLoading(false);
	};
	const subFieldsRender =(label, itemValue, prefix="") =>{
		let ClassNames='font-semibold';
		if(label!=="Budget"){
			ClassNames+= itemValue && parseInt(itemValue)>0?' text-green-500':' text-red-500';{/* Red color class="text-red-500" */}
		}
		return(
			<div>
				<label className='font-bold'>{label}: </label>
				<span className={ClassNames}>{itemValue?prefix+itemValue:" -"}</span>
			</div>
		)	
	};
	const renderJobAdCards = (item,index) =>{
				return(
				<Card className='w-full text-xs' bodyStyle={{ padding: 10, display: 'grid', rowGap: '0.2rem' }}>
					<div className='flex justify-between items-center'>
						{item?.facebook_ad_details?.ad_effective_status==="CAMPAIGN_PAUSED"?(
							<Space>
								<PauseCircleOutlined className='text-blue-500'/>
								<span>Paused - Reached Monthly Budget</span>
							</Space>
						):
						item?.status==="ACTIVE"?(
						<Space>
							<CheckCircleFilled className='text-green-500'/>
							<span>Active</span>
						</Space>
						):(
						<Space>
							<CloseCircleOutlined className='text-red-500'/>
							<span>Inactive</span>
						</Space>
						)}
						<Button type='text' icon={<EllipsisOutlined />} />
					</div>
					<div>
						Started running on {moment(item?.created_at).format('MMM D, YY')}
					</div>
					<Space>
						<span className='text-xs'>Platforms:</span>
						<Space size={3}>
							<FacebookFilled className='text-indigo-800'/>
							<InstagramOutlined className='text-pink-600'/>
						</Space>
					</Space>
					<div>
						<label className='font-bold'>Job Title: </label>
						<span className='font-semibold'>{item.title}</span>
					</div>
					<div>
						<label className='font-bold'>Location: </label>
						<span className='font-semibold'>{item.location}</span>
					</div>
					<div className='flex justify-between items-center'>
						{subFieldsRender("Budget",item?.ad_budget>=0?item.ad_budget*2:null,currencyContext.currency)}
						{subFieldsRender("Spent",item?.facebook_ad_details?.spend>=0?item.facebook_ad_details.spend*2:null,currencyContext.currency)}
					</div>
					<div className='flex justify-between items-center'>
						{subFieldsRender("Clicks",item?.facebook_ad_details?.clicks>=0?Math.round(item.facebook_ad_details.clicks*1.5):null)}
						{subFieldsRender("Reach",item?.facebook_ad_details?.reach)}
					</div>
					<Card className='w-full mt-2' hoverable bodyStyle={{ padding: 10 }}>
						<Space size={8}>
							<Avatar
								src={<img src={null} alt="avatar" />}
								size="large" className='border border-gray-300'
							/>
							<Space direction='vertical' size={0}>
								<div className='font-bold text-sm'>{item.buyer.company_name}</div>
								<div className='text-xs'>Sponsored</div>
							</Space>
						</Space>
						<div className='mt-2'>
							<Paragraph
								ellipsis={{ rows: 6 }}
								className='text-xs'
							>
								{item.text_main}
							</Paragraph>
						</div>
						<div className='text-right'>
							<CheckCircleFilled className='text-green-500' />
						</div>
						<div className='mt-1 text-center bg-gray-200'>
							<Image
								width={150}
								preview={false}
								src={item.image_url}
							/>
						</div>
						<div className='flex justify-between items-center mt-2'>
							<div>
								<div className='uppercase font-semibold text-xxs'>
									{item?.buyer?.company_name}
								</div>
								<div className='font-bold text-xxs'>
									{item.title} in {item.location}
								</div>
							</div>
							<Button size='small' className='!text-xs bg-gray-200 m-1' href={item.landing_page_url}>
								Learn More
							</Button>
						</div>
					</Card>
				</Card>
				)
	}
	const handleSizeChange = (current, newSize) => {
		setPageSize(newSize);
		setCurrentPage(1);
		getJobAD(newSize,1,jobStatus,budget);
	  };
	useEffect(()=>{
		getData()
	},[]);

	return (
		<div>
			<div className='flex flex-wrap justify-between items-center'>
				<div className='uppercase font-bold text-xl'>Social Media Promo</div>
				<Space wrap size={20}>
				<Space wrap size='small'>
						{/* Button className='w-36' */}
						<Button type="primary" className='!capitalize' onClick={() => history.push("/socialconfigure")} icon={<SettingOutlined />}>Configure</Button>
					</Space>
					<Space wrap size='small'>
						<label>Job Status</label>
						<Select
							className='w-36'
							defaultValue={constants.ActiveJS}
							onChange={(e)=>{setCurrentPage(1); setJobStatus(e);getJobAD(pageSize,1,e,budget)}}
							options={[
								// {
								// 	value: constants.AllJobsJS,
								// 	label: 'Show All',
								// },
								{
									value: constants.ActiveJS,
									label: 'Active',
								},
								{
									value: constants.InActiveJS,
									label: 'Inactive',
								},
								{
									value: constants.ErrorJS,
									label: 'Error',
								},
							]}
						/>
					</Space>
					<Space wrap size='small'>
						<label>Budget</label>
						<Select
							className='w-36'
							defaultValue='allBudget'
							onChange={(e)=>{setCurrentPage(1); setBudget(e);getJobAD(pageSize,1,jobStatus,e)}}
							options={[
								{
									value: 'allBudget',
									label: 'Show All',
								},
								{
									value: constants.SpendingBudget,
									label: constants.SpendingBudget,
								},
								{
									value: constants.SpentBudget,
									label: constants.SpentBudget,
								},
								{
									value: constants.NotLiveBudget,
									label: constants.NotLiveBudget,
								},
							]}
						/>
					</Space>
					{/* <Space wrap size='small'>
						<label>Occupation</label>
						<Select
							className='w-36'
							defaultValue="allOccupation"
							options={[
								{
									value: 'allOccupation',
									label: 'Show All',
								},
								{
									value: 'occupationList',
									label: 'Occupation List',
								},
							]}
						/>
					</Space> */}
				</Space>
			</div>
			<Divider className='my-3' />
			<Spin spinning={Loading}>
			{data.length>0?(
			<div className='mt-3'>
				<Row gutter={[{ sm: 16, xxl: 8 }, { sm: 16, xxl: 8 }]}>
				{data?.map((item,index)=>{
					return(
						<Col md={12} lg={8} xxl={6} key={index}>
							{renderJobAdCards(item,index)}
						</Col>
					);
				})}
				</Row>
				<div className='mt-3 grid place-content-end'>
					<Pagination 
						showSizeChanger
						pageSizeOptions={["24","48","96","192"]}
						pageSize={pageSize}
						onShowSizeChange={handleSizeChange}
						current={currentPage} 
						total={total} 
						onChange={(e)=>{setCurrentPage(e); getJobAD(pageSize,e,jobStatus,budget);}}/>
				</div>
			</div>
			):<Empty/>}
			</Spin>
		</div>
	);
};

export default SocialMediaPromo;
