import { Tabs, Divider } from 'antd';
import { UnorderedListOutlined, CalendarOutlined } from '@ant-design/icons';
import DailyReport from './DailyReport';
import MonthReport from './MonthReport';
import { useState } from 'react';
import { useEffect } from 'react';
import OccupationReport from './OccupationReport';
import SpendPerJobReport from './SpendPerJobReport';
import constants from '../../constants/constants';
import OccupationMatrix from './OccupationMatrix';
import ApplicationLog from './ApplicationLog';

const { TabPane } = Tabs;

const ApplicationReport = () => {

  //Variable declaration
  const [dateValues,setDateValues]=useState(null);
  const [tabValue,seTabValue]=useState(2)

  //Functions
  //Functon to set changed date value
  const changeDate=(selectedDate)=>{
    setDateValues(selectedDate);
  }

	//On load function call
	// useEffect(()=>{
	// },[tabValue]);

	return (
		<div>
      <div className='uppercase font-bold text-xl'>Application Report</div>
			<Divider className='my-3'/>
			<Tabs defaultActiveKey="2" onChange={(e)=>seTabValue(e)}>
      <TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.AppReportTab2}</span> }
        key={2}
      >
        <MonthReport changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
			<TabPane
        tab={ <span className='uppercase'><UnorderedListOutlined className='text-xl' />{constants.AppReportTab1}</span> }
        key={1}
      >
        <DailyReport changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
      <TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.AppReportTab3}</span> }
        key={3}
      >
        <OccupationReport changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
      <TabPane
        tab={ <span className='uppercase'><CalendarOutlined className='text-xl' />{constants.AppReportTab4}</span> }
        key={4}
      >
        <OccupationMatrix />
      </TabPane>
      <TabPane
        tab={ <span className='uppercase'><UnorderedListOutlined className='text-xl' />{constants.AppReportTab5}</span> }
        key={5}
      >
        <SpendPerJobReport changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
      <TabPane
        tab={ <span className='uppercase'><UnorderedListOutlined className='text-xl' />{constants.AppReportTab6}</span> }
        key={6}
      >
        <ApplicationLog changeDate={changeDate} dateSelectedValue={dateValues}/>
      </TabPane>
			</Tabs>
		</div>
	);
};

export default ApplicationReport;